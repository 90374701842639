<template>
  <div>
    <c-message
      v-if="hasErrors"
      class="mb-8"
      type="error"
    >
    <p>{{ errorMsg }}</p>
    </c-message>
    <form
      ref="form"
      @submit.prevent="next"
    >
      <c-title>Kosten/Finanzierung</c-title>

      <p class="mt-8 mb-8">
        Bei Angaben in Euro sind nur Ziffern (ohne Zeichen wie Punkt, Komma, EUR, € etc.) erlaubt.
      </p>
      <c-form-text
        v-model="fields.total_cost"
        :errors="errors.total_cost"
        class="mb-8"
        label="Gesamtkosten (in Euro)"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-text
        v-model="fields.planned_income"
        :errors="errors.planned_income"
        class="mb-8"
        label="Geplante Einnahmen (in Euro, Eintrittserlöse o. ä.)"
        :help="`Hinweis: Es ist keine Voraussetzung für die Antragstellung, dass Sie Einnahmen und/oder Eigenmittel angeben. Wenn Sie nicht mit Einnahmen rechnen oder keine Eigenmittel einbringen, tragen Sie in diese Felder bitte 0 ein. Das gilt auch für die Angaben zu den Drittmitteln (wenn Sie hier 0 eintragen, tragen Sie bitte in die darauffolgenden Felder »zugesagt durch« bzw. »beantragt/noch zu beantragen bei« einen Spiegelstrich ein).`"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-text
        v-model="fields.funds"
        :errors="errors.funds"
        class="mb-8"
        label="Eigenmittel/Eigenleistung (in Euro)"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-text
        v-model="fields.secured_third_party_funds"
        :errors="errors.secured_third_party_funds"
        class="mb-8"
        label="Gesicherte Drittmittel (in Euro)"
        help="(bereits zugesagte Förderungen z. B. von anderen Stiftungen oder aus öffentlichen Mitteln)"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-text
        v-model="fields.secured_third_party_funds_desc"
        :errors="errors.secured_third_party_funds_desc"
        class="mb-8"
        label="zugesagt durch"
        autocomplete="honorific-suffix"
        required
      />
      <c-form-text
        v-model="fields.unsecured_third_party_funds"
        :errors="errors.unsecured_third_party_funds"
        class="mb-8"
        label="Ungesicherte Drittmittel"
        help="(in Euro; z. B. bei anderen Stiftungen oder aus öffentlichen Mitteln beantragte oder noch zu beantragende Mittel)"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-text
        v-model="fields.unsecured_third_party_funds_desc"
        :errors="errors.unsecured_third_party_funds_desc"
        class="mb-8"
        label="beantragt/noch zu beantragen bei"
        autocomplete="honorific-suffix"
        required
      />
      <c-form-text
        v-model="fields.requested_amount"
        :errors="errors.requested_amount"
        class="mb-8"
        label="Bei der Hamburgischen Kulturstiftung hiermit beantragt (in Euro)"
        help="(die maximale Antragssumme beträgt 15.000 Euro)"
        autocomplete="honorific-suffix"
        inputmode="numeric"
        pattern="[0-9]*"
        required
      />
      <c-form-fieldset
        legend="Haben Sie bzw. Ihre Einrichtung in den letzten fünf Jahren bereits eine Förderung der Hamburgischen Kulturstiftung erhalten?"
        class="mb-8"
      >
        <c-form-radio
          v-model="fields.received_fund"
          class="mr-5"
          value="Ja"
          label="Ja, und zwar für folgende(s) Projekt(e) (bitte mit Titel, Jahreszahl und Fördersumme angeben):"
          name="received_funds"
          required
          inline
        />
        <transition name="fade">
          <c-form-text
            v-if="fields.received_fund === 'Ja'"
            v-model="fields.received_fund_desc"
            :errors="errors.received_fund_desc"
            class="mb-8"
            autocomplete="honorific-suffix"
            tag="textarea"
            maxlength="300"
            rows="5"
            required
            inline
          />
        </transition>
        <br>
        <c-form-radio
          v-model="fields.received_fund"
          class="mr-5"
          value="Nein"
          label="Nein"
          name="received_funds"
          required
          inline
          @change="resetExplainer"
        />
      </c-form-fieldset>
      <c-button
        type="button"
        class="mr-5"
        color="secondary"
        @click="previous"
      >
        Zurück
      </c-button>
      <c-button>
        Nächster Schritt
      </c-button>
    </form>
  </div>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep2',

  extends:
    BRequestStep,

  data () {
    return {
      step: 2,
      id: 'reportData2',
      fields: {
        total_cost: '',
        planned_income: '',
        funds: '',
        secured_third_party_funds: '',
        secured_third_party_funds_desc: '',
        unsecured_third_party_funds: '',
        unsecured_third_party_funds_desc: '',
        requested_amount: '',
        received_fund: '',
        received_fund_desc: ''
      }
    }
  },

  computed: {
    hasErrors () {
      return this.$store.state.errors.steps.includes(this.step)
    }
  },

  methods: {
    resetExplainer: function () {
      this.fields.received_fund_desc = ''
    }
  }
}
</script>
